import React from "react";
import { useLocation } from "react-router-dom";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lang = queryParams.get("lang");
  const isArabic = lang === "ar";
  return (
    <div className="container">
      <div
        className="privacy-policy"
        style={{
          direction: isArabic ? "rtl" : "ltr",
          textAlign: isArabic ? "right" : "left",
        }}
      >
        <h1>{isArabic ? "سياسة الخصوصية" : "Privacy Policy"}</h1>
        <p>
          {isArabic
            ? "في SJL، نولي اهتمامًا كبيرًا لخصوصيتك ونلتزم بحماية بياناتك الشخصية. توضح سياسة الخصوصية هذه كيفية جمع البيانات الشخصية للمستخدمين واستخدامها وحمايتها، مع التركيز على الأدوار المتعلقة بالمدارس والنقل وأولياء الأمور عند استخدام منصتنا."
            : "At SJL, we take your privacy seriously and are committed to safeguarding your personal data. This Privacy Policy explains how we collect, use, and protect the personal data of users, specifically related to the roles of schools, transports, and parents, when interacting with our platform."}
        </p>

        <h2>1. {isArabic ? "المقدمة" : "Introduction"}</h2>
        <p>
          {isArabic
            ? "تكرس SJL جهودها لتقديم الخدمات للمدارس وشركات النقل وأولياء الأمور. نقوم بجمع وإدارة المعلومات الشخصية لضمان الأداء السليم لخدماتنا، بما في ذلك إدارة المدارس، ولوجستيات النقل، والتواصل مع أولياء الأمور. توضح سياسة الخصوصية هذه كيفية التعامل مع البيانات الشخصية لكل دور."
            : "SJL is dedicated to providing services for schools, transport companies, and parents. We collect and manage personal information to ensure the proper functioning of our services, including school administration, transport logistics, and parent communication. This Privacy Policy outlines how we handle the personal data of each role."}
        </p>

        <h2>2. {isArabic ? "جمع البيانات" : "Data Collection"}</h2>
        <p>
          {isArabic
            ? "نقوم بجمع أنواع البيانات الشخصية التالية بناءً على الدور:"
            : "We collect the following types of personal data depending on the role:"}
        </p>

        <h3>A. {isArabic ? "المدارس" : "Schools"}</h3>
        <ul>
          <li>
            <strong>
              {isArabic ? "معلومات المدرسة" : "School Information"}
            </strong>
            :{" "}
            {isArabic
              ? "اسم المدرسة، العنوان، تفاصيل الاتصال (رقم الهاتف، البريد الإلكتروني)، معلومات التسجيل."
              : "School name, address, contact details (phone number, email), registration information."}
          </li>
          <li>
            <strong>{isArabic ? "بيانات الطلاب" : "Student Data"}</strong>:{" "}
            {isArabic
              ? "معلومات عن الطلاب المسجلين في المدرسة مثل الأسماء، الصفوف، الحضور، والتقدم الأكاديمي."
              : "Information about students enrolled in the school, such as names, grades, attendance, and academic progress."}
          </li>
          <li>
            <strong>{isArabic ? "بيانات الموظفين" : "Staff Data"}</strong>:{" "}
            {isArabic
              ? "تفاصيل حول الطاقم التعليمي والإداري، بما في ذلك الأسماء، معلومات الاتصال، الأدوار، وساعات العمل."
              : "Details of teaching and administrative staff, including names, contact information, roles, and working hours."}
          </li>
        </ul>

        <h3>B. {isArabic ? "النقل" : "Transports"}</h3>
        <ul>
          <li>
            <strong>
              {isArabic ? "معلومات الشركة" : "Company Information"}
            </strong>
            :{" "}
            {isArabic
              ? "اسم شركة النقل، تفاصيل التسجيل، ومعلومات الاتصال."
              : "Transport provider company name, registration details, and contact information."}
          </li>
          <li>
            <strong>
              {isArabic ? "معلومات المركبات" : "Vehicle Information"}
            </strong>
            :{" "}
            {isArabic
              ? "بيانات المركبات مثل أرقام التسجيل، الأنواع، ومناطق الخدمة."
              : "Data related to transport vehicles, including vehicle registration numbers, types, and service areas."}
          </li>
          <li>
            <strong>
              {isArabic
                ? "بيانات المسارات والجدولة"
                : "Route and Scheduling Data"}
            </strong>
            :{" "}
            {isArabic
              ? "معلومات حول مسارات النقل، الجداول الزمنية، وتفاصيل السائقين."
              : "Information about transport routes, schedules, and driver details."}
          </li>
          <li>
            <strong>{isArabic ? "بيانات المستخدمين" : "User Data"}</strong>:{" "}
            {isArabic
              ? "معلومات عن طاقم النقل مثل الأسماء، معلومات الاتصال، والأدوار."
              : "Information about transport personnel, such as names, contact information, and roles."}
          </li>
        </ul>

        <h2>3. {isArabic ? "استخدام البيانات" : "Use of Data"}</h2>
        <p>
          {isArabic
            ? "نستخدم البيانات المجمعة للأغراض التالية:"
            : "We use the collected data for the following purposes:"}
        </p>
        <ul>
          <li>
            <strong>{isArabic ? "إدارة المدارس" : "Schools"}</strong>:{" "}
            {isArabic
              ? "إدارة سجلات الطلاب، التواصل مع أولياء الأمور، تتبع تقدم الطلاب، وإدارة عمليات المدرسة."
              : "To manage student records, communicate with parents, track student progress, and manage school operations."}
          </li>
          <li>
            <strong>{isArabic ? "النقل" : "Transports"}</strong>:{" "}
            {isArabic
              ? "إدارة جداول المركبات، المسارات، تعيين السائقين وضمان نقل الطلاب بأمان وفي الوقت المحدد."
              : "To manage transport logistics, including vehicle schedules, routes, and driver assignments, and to ensure safe and timely transportation of students."}
          </li>
          <li>
            <strong>{isArabic ? "أولياء الأمور" : "Parents"}</strong>:{" "}
            {isArabic
              ? "للحفاظ على التواصل مع الآباء بشأن تعليم أطفالهم، أنشطة المدرسة، وخدمات النقل."
              : "To maintain communication with parents regarding their children’s education, school activities, and transport services."}
          </li>
        </ul>

        <h2>4. {isArabic ? "حماية البيانات" : "Data Protection"}</h2>
        <p>
          {isArabic
            ? "تطبق SJL تدابير تقنية وتنظيمية معقولة لحماية البيانات الشخصية من الوصول غير المصرح به أو التعديل أو الإفصاح أو التدمير. تشمل هذه التدابير:"
            : "SJL employs reasonable technical and organizational measures to protect personal data from unauthorized access, alteration, disclosure, or destruction. These measures include:"}
        </p>
        <ul>
          <li>
            {isArabic
              ? "خوادم وقواعد بيانات آمنة."
              : "Secure servers and databases."}
          </li>
          <li>
            {isArabic
              ? "تشفير البيانات الحساسة."
              : "Encryption of sensitive data."}
          </li>
          <li>
            {isArabic
              ? "ضوابط وصول لتحديد من يمكنه عرض أو تعديل البيانات الشخصية."
              : "Access controls to limit who can view or modify personal data."}
          </li>
        </ul>

        <h2>5. {isArabic ? "مشاركة البيانات" : "Sharing of Data"}</h2>
        <p>
          {isArabic
            ? "لا نقوم ببيع أو تأجير أو مشاركة البيانات الشخصية مع أطراف ثالثة لأغراض تسويقية. ومع ذلك، قد نشارك البيانات الشخصية في الحالات التالية:"
            : "We do not sell, rent, or share personal data with third parties for marketing purposes. However, we may share personal data in the following circumstances:"}
        </p>
        <ul>
          <li>
            <strong>
              {isArabic ? "مع مزودي الخدمات" : "With service providers"}
            </strong>
            :
            {isArabic
              ? "لتمكيننا من تقديم خدماتنا (مثل شركات النقل، مزودي تكنولوجيا المعلومات)."
              : "To enable us to provide our services (e.g., transport companies, IT providers)."}
          </li>
          <li>
            <strong>{isArabic ? "مع السلطات" : "With authorities"}</strong>:
            {isArabic
              ? "عندما يقتضي القانون أو الالتزامات القانونية ذلك (مثل التقارير للجهات التعليمية)."
              : "When required by law or for legal obligations (e.g., reporting to educational authorities)."}
          </li>
          <li>
            <strong>{isArabic ? "مع الشركاء" : "With partners"}</strong>:
            {isArabic
              ? "في حالات الجهود التعاونية (مثل المبادرات التعليمية) التي تتطلب مشاركة البيانات."
              : "In cases of collaborative efforts (e.g., educational initiatives) where data sharing is necessary."}
          </li>
        </ul>

        <h2>6. {isArabic ? "حقوق المستخدمين" : "User Rights"}</h2>
        <p>
          {isArabic
            ? "بناءً على دورك والقوانين المعمول بها، قد يكون لديك الحقوق التالية بشأن بياناتك الشخصية:"
            : "Depending on your role and applicable law, you may have the following rights regarding your personal data:"}
        </p>
        <ul>
          <li>
            <strong>{isArabic ? "الوصول" : "Access"}</strong>:
            {isArabic
              ? "الحق في طلب نسخة من البيانات الشخصية التي نحتفظ بها عنك."
              : "The right to request a copy of the personal data we hold about you."}
          </li>
          <li>
            <strong>{isArabic ? "التصحيح" : "Correction"}</strong>:
            {isArabic
              ? "الحق في تصحيح أي بيانات غير دقيقة أو غير مكتملة."
              : "The right to correct any inaccurate or incomplete data."}
          </li>
          <li>
            <strong>{isArabic ? "الحذف" : "Deletion"}</strong>:
            {isArabic
              ? "الحق في طلب حذف بياناتك، مع مراعاة الالتزامات القانونية."
              : "The right to request the deletion of your data, subject to legal obligations."}
          </li>
          <li>
            <strong>{isArabic ? "التقييد" : "Restriction"}</strong>:
            {isArabic
              ? "الحق في تقييد معالجة بياناتك في ظروف معينة."
              : "The right to restrict the processing of your data under certain circumstances."}
          </li>
          <li>
            <strong>
              {isArabic ? "قابلية نقل البيانات" : "Data Portability"}
            </strong>
            :
            {isArabic
              ? "الحق في طلب نقل بياناتك إلى مزود خدمة آخر."
              : "The right to request the transfer of your data to another service provider."}
          </li>
        </ul>
        <p>
          {isArabic
            ? "لممارسة هذه الحقوق، يرجى الاتصال بنا عبر [معلومات الاتصال]."
            : "To exercise these rights, please contact us at [contact information]."}
        </p>

        <h2>7. {isArabic ? "الاحتفاظ بالبيانات" : "Data Retention"}</h2>
        <p>
          {isArabic
            ? "سنحتفظ ببياناتك الشخصية طالما كان ذلك ضروريًا لتحقيق الأغراض الموضحة في سياسة الخصوصية هذه أو كما يقتضيه القانون. بعد هذه الفترة، سيتم حذف بياناتك أو إخفاء هويتها بشكل آمن."
            : "We will retain your personal data for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. After this period, your data will be securely deleted or anonymized."}
        </p>

        <h2>
          8.{" "}
          {isArabic
            ? "ملفات تعريف الارتباط وتقنيات التتبع"
            : "Cookies and Tracking Technologies"}
        </h2>
        <p>
          {isArabic
            ? "نستخدم ملفات تعريف الارتباط وتقنيات التتبع المشابهة لتحسين تجربة المستخدم وتحليل كيفية استخدام منصتنا. تساعدنا هذه التقنيات في تذكر تفضيلاتك وتحسين خدماتنا."
            : "We use cookies and similar tracking technologies to enhance the user experience and analyze how our platform is used. These technologies help us remember your preferences and improve our services."}
        </p>

        <p>
          {isArabic
            ? "يمكنك إدارة تفضيلات ملفات تعريف الارتباط من إعدادات متصفحك. يرجى ملاحظة أن تعطيل ملفات تعريف الارتباط قد يؤثر على وظائف منصتنا."
            : "You can manage your cookie preferences in your browser settings. Please note that disabling cookies may affect the functionality of our platform."}
        </p>

        <h2>
          9.{" "}
          {isArabic
            ? "تغييرات على سياسة الخصوصية هذه"
            : "Changes to This Privacy Policy"}
        </h2>
        <p>
          {isArabic
            ? "قد نقوم بتحديث سياسة الخصوصية هذه بشكل دوري لتعكس التغييرات في ممارساتنا أو لأسباب تشغيلية أو قانونية أو تنظيمية."
            : "We may update this Privacy Policy periodically to reflect changes in our practices or for other operational, legal, or regulatory reasons."}
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
